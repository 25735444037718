<template>
  <div class="home">
    <Auction ref="auctionEL" />
  </div>
</template>

<script>
import Auction from "./modules/AuctionNew.vue";
import { useStore } from "vuex";
import {
  liveDetail,
  loginSocketApi,
  joinRoomApi,
  sendChatMsg,
  clickLike,
} from "@/api/live";
import { ref, onBeforeMount, reactive, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { TcPlayer } from "../../assets/js/TcPlayer-module-2.4.1";
import { Toast } from "vant";
import webSocket from "@/utils/webSocket";

export default {
  components: { Auction },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const id = ref(""); // 直播id
    const albumId = ref(""); // 拍卖专场id
    const auctionEL = ref(null);
    const liveData = ref({});
    const liveStatue = ref(0);
    const sdata = reactive({
      socketServe: webSocket.Instance,
    });
    const client_id = ref(""); // socket连接标识
    // 获取用户可出价额度
    const mer_name = computed(() => {
      return store.getters.mer_name;
    });
    const nickname = computed(() => {
      return store.getters.uname;
    });

    const live_screen_direction = ref(""); // 横屏 2 竖屏 1

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      store.dispatch("user/getAccountInfo");
      id.value = route.query.id;

      nextTick(() => {
        liveDetail(id.value).then((res) => {
          liveData.value = res.data;

          like_num.value = res.data.like_num;
          // 赋值给组件
          if (res.data.live_type == "auction_album") {
            auctionEL.value.roomId = id.value;
            auctionEL.value.albumId = res.data.relation_id;
            auctionEL.value.loadAlbumDetail();
            auctionEL.value.loadMsgAction(id.value);
          }

          live_screen_direction.value =
            res.data.extend_data.live_screen_direction;

          liveStatue.value = res.data.stream_state == 1 ? 0 : -1;
          if (liveData.value.live_status == 2) {
            if (res.data.stream_state == 1) {
              setTimeout(() => {
                playAction();
              }, 100);
            }
          }
        });

        if (!webSocket.Instance.ws) {
          webSocket.Instance.connect();
          sdata.socketServe = webSocket.Instance;
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          sdata.socketServe.registerCallBack("openSocket", () => {
            // sendData({
            //   type: "join_auction_room",
            //   auction_id: id.value,
            // });
          });
        } else if (client_id.value || webSocket.Instance.client_id) {
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          joinRoom();
          // sendData({
          //   type: "join_auction_room",
          //   auction_id: id.value,
          // });
        }
      });
    });
    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });
    let loadAnm = false;
    let oldNum = 0;
    const sockedMes = (d) => {
      if (d.type == "init") {
        client_id.value = d.client_id;
        webSocket.Instance.client_id = d.client_id;
        loginSocket();
      } else if (d.type == "stream_state") {
        if (d.group_id == id.value) {
          if (d.data.state === 1) {
            if (liveData.value.live_status == 2) {
              playAction();
            }
          } else {
            if (player && player.playing()) {
              player.pause();
              isPlay.value = false;
              return false;
            }
            liveStatue.value = -1;
          }
        }
      } else if (d.type === "click_like") {
        if (d.data.uid != uid.value) {
          if (loadAnm) {
            oldNum += Number(d.data.num);
            return false;
          }
          loadAnm = true;
          let n = oldNum + Number(d.data.num);
          oldNum = 0;
          let num = n > 10 ? 10 : n;
          like_num.value += n;

          for (let i = 0; i < num; i++) {
            likeTimeout1 = setTimeout(() => {
              loadLike();
              if (i == num - 1) {
                loadAnm = false;
                if (likeTimeout1) clearTimeout(likeTimeout1);
                likeTimeout1 = setTimeout(() => {
                  if (!likeTimeout) {
                    viewList.value = [];
                  }
                  likeTimeout1 = null;
                }, 1000);
              }
            }, i * 200);
          }
        }
      } else {
        if (auctionEL.value) {
          auctionEL.value.sockedMes(d);
        }
      }
    };

    const loginSocket = () => {
      loginSocketApi({
        client_id: client_id.value,
      })
        .then(() => {
          joinRoom();
        })
        .catch((message) => Toast(message));
    };

    const joinRoom = () => {
      joinRoomApi({
        client_id: client_id.value || webSocket.Instance.client_id,
        room_type: "live",
        room_id: id.value,
      })
        .then()
        .catch((message) => Toast(message));
    };

    let player = null;
    const isPlay = ref(false);

    const loadVideo = () => {
      const w = document.documentElement.clientWidth;
      let h = document.documentElement.clientHeight;
      if (live_screen_direction.value == 2) {
        h = (w / 16) * 9;
      }
      player = new TcPlayer("id_test_video", {
        webrtc: liveData.value.play_url.webrtc,
        m3u8: liveData.value.play_url.hls,
        flv: liveData.value.play_url.flv,
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: {
          style: "cover",
          src: liveData.value.cover_image,
        },
        width: w,
        height: h,
        live: true,
        controls: "none",
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        listener: (d) => {
          if (d.type == "loadeddata") {
            const video = document.getElementsByTagName("video");
            if (video && video[0]) {
              video[0].style.objectFit = "cover";
            }
          } else if (d.type == "error") {
            liveStatue.value = -1;
          } else if (d.type == "play") {
            liveStatue.value = 1;
            isPlay.value = true;
          }
        },
      });
    };
    const playAction = () => {
      if (player) {
        player.play();
        isPlay.value = true;
        return false;
      }
      liveStatue.value = 0;
      setTimeout(() => {
        loadVideo();
      }, 100);
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const speakVal = ref("");
    const sayAction = () => {
      // 发言
      if (speakVal.value) {
        sendChatMsg({
          live_id: id.value,
          msg_type: "text",
          content: speakVal.value,
        })
          .then(() => {
            const data = {
              type: "send_msg_new",
              data: {
                user: {
                  nickname: nickname.value,
                },
                content: speakVal.value,
              },
              group_id: id.value,
            };
            auctionEL.value.sockedMes(data);
            speakVal.value = "";
          })
          .catch((message) => Toast(message));
      }
    };
    let likeTimeout = null;
    let likeTimeout1 = null;
    let likeNum = 0;
    const like_num = ref(0);
    const clickLikeAction = () => {
      loadLike();
      if (likeTimeout) clearTimeout(likeTimeout);
      likeNum += 1;
      likeTimeout = setTimeout(() => {
        likeAction(likeNum);

        if (!likeTimeout1) {
          viewList.value = [];
        }

        likeNum = 0;
        likeTimeout = null;
      }, 1000);
    };
    const likeAction = (num) => {
      like_num.value += num;
      clickLike({ live_id: id.value, num: num });
    };
    const viewList = ref([]); // 点赞列表
    const loadLike = () => {
      let arr = [
        "https://artimg2.artart.cn/image/20210602/f915d7a7bd28f9b7c795dcebe2f26526.png",
        "https://artimg2.artart.cn/image/20210602/7d9c4fbb4874a2684be4df0723552c29.png",
        "https://artimg2.artart.cn/image/20210602/54202b9f41a0d6ffac8cc2c19bdd5322.png",
        "https://artimg2.artart.cn/image/20210602/369c052518e9e34d154f101b08e0b934.png",
        "https://artimg2.artart.cn/image/20210603/e552e4a1fc92318c97cc3ba900ddcb0f.png",
        "https://artimg2.artart.cn/image/20210603/e552e4a1fc92318c97cc3ba900ddcb0f.png",
      ];
      viewList.value.push(arr[Math.floor(Math.random() * arr.length)]);
    };

    return {
      liveData,
      isPlay,
      liveStatue,
      albumId,
      auctionEL,
      mer_name,
      speakVal,
      nickname,
      live_screen_direction,
      viewList,
      like_num,

      playAction,
      backAction,
      sayAction,
      clickLikeAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
  overflow: hidden;

  .live-title {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 88px;
    z-index: 100;

    .back-img {
      margin-left: 32px;
      height: 88px;

      img {
        width: 48px;
        height: 48px;
        display: block;
        margin-right: 10px;
      }
    }

    .title-right {
      color: #fff;
      overflow: hidden;

      .live-number {
        font-size: 20px;
      }
    }
  }

  .live {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background: #222;
    position: relative;

    .hengping {
      margin-top: 88px;
      height: 422px;
    }

    .txt {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 500;
      color: #fff;
      z-index: 2;

      &.h {
        height: 422px;
        margin-top: 88px;
      }
    }

    .live-dialog {
      position: absolute;
      right: 24px;
      bottom: 18px;
      z-index: 100;

      div {
        margin-left: 8px;
        width: 76px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .ft11 {
    font-size: 22px;
  }
}

.play-icon {
  position: absolute;
  margin-left: -35px;
  margin-top: -35px;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  z-index: 1000;
}

.play-icon image {
  width: 150px;
  height: 150px;
  display: block;
}

.live-bottom {
  position: fixed;
  left: 32px;
  right: 32px;
  bottom: 68px;
  z-index: 100;

  &.jz {
    left: 112px;
  }

  .search {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 45px;
    height: 64px;
    padding: 0 20px;

    input {
      background: none;
      border: none;
      width: 100%;
    }
  }

  .share,
  .like {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 45px;
    margin-left: 16px;
    position: relative;

    img {
      width: 40px;
      height: 40px;
      display: block;
    }
    .tag-view {
      position: absolute;
      top: -20px;
      left: -10px;
      right: -10px;
    }
    .tag {
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 15px;
      font-size: 20px;
      padding: 0 10px;
      min-width: 30px;
    }
  }
}

.like-view {
  width: 150px;
  height: 500px;
  position: fixed;
  right: -16px;
  bottom: 130px;
  z-index: 100;
  overflow: visible;
}

.animate {
  position: absolute;
  font-size: 30px;
  color: white;
  text-align: center;
  line-height: 50px;
  width: 80px;
  transform: translateY(0);
  z-index: 10;
  left: 75px;
  bottom: 0;
}

.animate0 {
  opacity: 0;
  animation: mymove 1.8s 1;
}

.animate1 {
  opacity: 0;
  animation: mymove1 1.8s 1;
}

.animate2 {
  opacity: 0;
  animation: mymove2 1.8s 1;
}

@keyframes mymove {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 75px;
  }
}

@keyframes mymove0 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 75px;
  }
}

@keyframes mymove1 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 0px;
  }
}

@keyframes mymove2 {
  0% {
    bottom: 0px;
    width: 0;
    height: 0;
    left: 75px;
  }

  45% {
    bottom: 200px;
    width: 90px;
    height: 90px;
    opacity: 1;
    left: 15px;
  }

  100% {
    bottom: 400px;
    width: 0px;
    height: 0;
    opacity: 0;
    left: 90px;
  }
}
</style>
