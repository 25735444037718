<template>
  <div>
    <div
      class="all-bg nochange"
      v-if="auctionData"
    >
      <template v-if="showType == 1 || !showType">
        <div
          class="top-bg nochange"
          :style="{ height: '211px', paddingTop: '100px', background: `url(${auctionData.product.image}!m640) no-repeat center`, backgroundSize: 'cover' }"
        ></div>
        <div
          class="bottom-bg"
          :style="{flex: 1, background: `url(${auctionData.product.image}!m640) no-repeat center`, backgroundSize: 'cover'}"
        ></div>
      </template>
      <div
        v-if="auctionData && (showType == 3 || !showType)"
        class="auction-info nochange"
      >
        <div class="auction-info-top flex align-items-c">
          <div class="auction-info-status">
            <div
              v-if="auctionData.auction_status == 2"
              class="auction-status-view"
            >{{albumData.album_status == 2 ? '待开拍' : '预展中'}}</div>
            <div
              v-else-if="auctionData.auction_status == 1"
              class="auction-status-view"
            >竞拍中</div>
            <div
              v-else
              class="auction-statstatus-viewus"
            >已落槌</div>
          </div>
          <div class="auction-info-name line1"><text
              v-if="auctionData.lot"
              class="mr5"
            >LOT{{auctionData.lot}} </text>{{ auctionData.product.store_name }}</div>
          <div
            class="auction-num"
            v-if="albumData.album_type != 1"
          ><text class="fw500">{{ auctionData.order_num }}</text>/{{ albumData.auction_num }}件</div>
        </div>

        <div class="auction-info-bottom flex align-items-c">
          <div class="flex-one flex align-items-c">
            <div
              class="auction-info-user flex align-items-c"
              v-if="auctionData.now_price"
            >
              <div
                class="user-avatar"
                v-if="auctionData.now_user_avatar"
              >
                <img :src="auctionData.now_user_avatar">
              </div>
              <div class="user-name line1">
                <template v-if="auctionData.now_user == uid">我的</template>
                <template v-else>{{ auctionData.now_user_name }}</template>
              </div>
              <div
                class="user-name"
                style="margin-left: 0;"
              >{{auctionData.auction_status > 2 ? '中拍' : '出价'}}</div>
            </div>
            <div
              class="auction-info-user flex align-items-c"
              v-else-if="auctionData.auction_status <= 2"
            >
              <div class="user-name">起拍价</div>
            </div>
            <div
              class="auction-info-price flex align-items-c"
              v-if="auctionData.now_price"
            >
              <template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{
        formatPriceNumber(auctionData.now_price, 2) }}
            </div>
            <div
              class="auction-info-price flex align-items-c"
              v-else-if="auctionData.auction_status <= 2"
            >无底价</div>
            <div
              v-if="albumData.album_type != 1 && auctionData.auction_status == 1 && !auctionData.now_price"
              class="is-wait"
            >等待出价...</div>
            <div
              v-if="albumData.album_type != 1 && auctionData.now_price && auctionData.is_confirm === 0"
              class="is-confirm"
            >出价待确认...</div>
          </div>
        </div>
      </div>
      <div
        v-if="auctionData && (showType == 2 || !showType)"
        class="auction-view nochange"
      >
        <div class="auction-status nochange">
          <template v-if="albumData.album_type == 1">
            <div
              v-if="auctionData.auction_status == 2"
              class="auction-status-view"
            >预展中</div>
            <!-- <div v-else-if="noshowTime" class="auction-status-view">正在拍卖...</div>
      <div v-else-if="showTime" :class="auction-status-view {{ hong ? 'jjjp' : '' }} item-flex-center">
        <div>{{showTime}}</div>
        <div>后结拍</div>
      </div> -->
            <div
              v-else-if="auctionData.auction_status == 1"
              class="auction-status-view"
            >正在拍卖...</div>
            <div
              v-else
              class="auction-statstatus-viewus"
            >已结拍</div>
          </template>
          <template v-else>
            <div
              v-if="auctionData.auction_status == 2"
              class="auction-status-view"
            >{{albumData.album_status == 2 ? '待开拍' : '预展中'}}</div>
            <div
              v-else-if="auctionData.auction_status == 1"
              class="auction-status-view"
            >竞拍中</div>
            <div
              v-else
              class="auction-statstatus-viewus"
            >已落锤</div>
            <div class="auction-number"><text class="fw500">{{ auctionData.order_num }}</text>/{{ albumData.auction_num }}件</div>
          </template>
        </div>
        <div class="auction-content textAlignC nochange">
          <div class="name nochange line2"><span
              v-if="auctionData.lot"
              class="fw500 mr5"
            >LOT{{auctionData.lot}} </span>{{ auctionData.product.store_name }}</div>
          <template v-if="auctionData.auction_status == 2 || (auctionData.auction_status == 1 && !auctionData.now_price)">
            <div
              v-if="!auctionData.start_price"
              class="price nochange"
            >无底价</div>
            <div
              v-else
              class="price nochange"
            ><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(auctionData.start_price, 2) }}</div>
            <div class="num nochange item-flex-center">{{ !auctionData.start_price ? '无底价起拍' : '起拍价' }}</div>
            <div
              v-if="auctionData.auction_status == 1"
              class="buyer nochange item-flex-center"
            >等待出价...</div>
          </template>
          <template v-else-if="auctionData.auction_status == 1">
            <div class="price nochange"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(auctionData.now_price, 2) }}</div>
            <div
              v-if="auctionData.is_confirm === 0"
              class="num nochange num1 item-flex-center"
            >出价待确认</div>
            <div
              v-else-if="auctionData.now_price"
              class="num nochange num1 item-flex-center"
            >当前价</div>
            <div
              v-if="auctionData.now_user == uid"
              class="buyer nochange item-flex-center"
            >我的出价</div>
            <div
              v-else
              class="buyer nochange item-flex-center"
            >
              <div class="l line1">{{ auctionData.now_user_name }}</div>
            </div>
          </template>
          <template v-else-if="auctionData.now_price">
            <div class="price nochange"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{ formatPriceNumber(auctionData.now_price, 2) }}</div>
            <div class="num num1 item-flex-center nochange">落槌价</div>
            <div
              v-if="auctionData.now_user == uid"
              class="buyer item-flex-center nochange"
            >我的出价</div>
            <div
              v-else
              class="buyer item-flex-center nochange"
            >
              <div class="l line1">{{ auctionData.now_user_name }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Comment from "./Comment.vue";
import LoginNotive from "./LoginNotive.vue";
import {
  albumDetail,
  checkAlbumBondPay,
  getLiveTopAuction,
  albumStartAuctionInfo,
  userNumberPlateHandle,
  auctionBidPrice,
} from "@/api/auction";
import { getConfigClassKeys } from "@/api/system";
import { formatPriceNumber, settingPrice } from "@/utils/util";
import { Toast, Swipe, SwipeItem, CountDown, Switch } from "vant";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import BidBondMoney from "../../auction/modules/BidBondMoney.vue";
import AuctionList from "./AuctionList.vue";
import AuctionDetailEL from "./AuctionDetail.vue";

export default {
  components: {
    LoginNotive,
    Comment,
    BidBondMoney,
    AuctionList,
    AuctionDetailEL,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [CountDown.name]: CountDown,
    [Switch.name]: Switch,
  },
  props: {
    merchant: Object,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const auctionDetailEL = ref(null); // 拍品详情组件
    const roomId = ref(""); // 直播间id
    const albumId = ref(""); // 专场id
    const status = ref(0); // 专场状态
    const albumData = ref(""); // 专场信息
    const isPayBond = ref(false); // 是否缴纳保证金
    const auctionData = ref(""); // 拍品信息
    const auctionList = ref(null); // 拍品列表组件
    const compel_anonymous = ref(1);
    const showType = ref(0);

    const loadMsgAction = (id) => {
      // commentEL.value.loadMsg(id);
      // loginNotiveEL.value.roomId = id;
    };

    const merchantInfo = computed(() => {
      if(props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const loadAlbumDetail = () => {
      // 获取配置
      getConfigClassKeys("auction_rule_set").then((res) => {
        compel_anonymous.value = res.data.compel_anonymous;
      });
      showType.value = Number(route.query.type);
      // 专场详情
      albumDetail(albumId.value)
        .then((res) => {
          const d = res.data;
          status.value = d.album_status;

          if (d.bond_type == 1 && d.bond_price) {
            isPayBond.value = d.isPayBond;
          }
          albumData.value = d;
          loadNowAuction();
        })
        .catch((message) => Toast(message));
    };

    const loadNowAuction = () => {
      let action = getLiveTopAuction;
      if (
        albumData.value &&
        (albumData.value.album_type == 2 || albumData.value.album_type == 3)
      ) {
        action = albumStartAuctionInfo;
      }
      // 获取当前拍品
      action({ album_id: albumId.value }).then((res) => {
        if (res.data && res.data.product_id) {
          if (
            res.data.now_price &&
            albumData.value.album_type == 1 &&
            res.data.bid_record
          ) {
            res.data.now_user_name = res.data.bid_record.user.nickname;
            res.data.now_user_avatar = res.data.bid_record.user.avatar;
          } else if (
            res.data.now_price &&
            (albumData.value.album_type == 2 ||
              albumData.value.album_type == 3) &&
            res.data.bid_record &&
            res.data.bid_record[0]
          ) {
            res.data.now_user_name = res.data.bid_record[0].user.nickname;
            res.data.now_user_avatar = res.data.bid_record[0].user.avatar;
          }

          auctionData.value = res.data;
          initPriceRule(res.data);
          initPrice(res.data);
        }
      });
    };

    const checkAlbumBondPayAction = () => {
      // 检查是否缴纳保证金
      checkAlbumBondPay(albumId.value).then((res) => {
        isPayBond.value = res.data.is_pay;
      });
    };
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 右按钮样式
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    const showPayBondMoney = ref(false);
    const payBondAction = () => {
      showPayBondMoney.value = true;
    };

    // 获取号牌
    const userNumber = computed(() => {
      return albumData.value.numberPlate && albumData.value.numberPlate.number
        ? albumData.value.numberPlate.number
        : "";
    });

    // 获取号牌
    const userNumberType = computed(() => {
      return albumData.value.numberPlate &&
        albumData.value.numberPlate.number_type
        ? albumData.value.numberPlate.number_type
        : "";
    });

    // 获取用户可出价额度
    const canBidPrice = computed(() => {
      return albumData.value.bond_type === 2
        ? (store.getters.account.balance || 0) * albumData.value.bond_bid_ratio
        : 0;
    });

    // 余额
    const account = computed(() => {
      return store.getters.account;
    });

    // 获取号牌
    const useNumberPlateAction = () => {
      userNumberPlateHandle({
        venue_id: albumData.value.venue_id,
      })
        .then((res) => {
          albumData.value.numberPlate = res.data;
        })
        .catch((message) => Toast(message));
    };

    const vanSwiper = ref(null); // 出价swiper

    const bidPriceList = ref([]);
    const priceIndex = ref(0);
    const lock = ref(false);
    const upBid = ref(null);

    const vanSwiperBid = ref(null);

    const priceData = ref([{ min: 0, max: "∞", price: 1000, type: 1 }]);

    const getPrice = (p, r) => {
      // 获取计算后的价格
      const price = settingPrice(p, priceData.value);

      setPrice(
        {
          bidNextPrice: Number(price),
          bidNextPriceCha: formatPriceNumber(
            Number(price) - Number(auctionData.value.now_price),
            2
          ),
        },
        r
      );
    };

    const setPrice = (data, refresh) => {
      bidPriceList.value = refresh ? [data] : bidPriceList.value.concat([data]);
      if (priceIndex.value == -1) {
        lock.value = false;
      } else {
        priceIndex.value = bidPriceList.value.length - 1;
        if (vanSwiperBid.value) {
          vanSwiperBid.value.swipeTo(priceIndex.value);
        }
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const initPriceRule = (newVal) => {
      if (newVal.bid_range_id) {
        if (newVal.bidRange) {
          priceData.value = newVal.bidRange.content;
        }
      } else {
        priceData.value = [
          { min: 0, max: "∞", price: Number(newVal.plus_price), type: 1 },
        ];
      }
    };
    const initPrice = (newVal) => {
      if (newVal && newVal.product_id) {
        if (newVal.now_price) {
          getPrice(newVal.now_price, 2);
        } else {
          if (upBid.value) clearTimeout(upBid.value);
          lock.value = true;
          if (newVal.start_price) {
            setPrice(
              {
                bidNextPrice: Number(newVal.start_price),
                bidNextPriceCha: formatPriceNumber(
                  Number(newVal.start_price) - Number(newVal.now_price),
                  2
                ),
              },
              1
            );
          } else {
            getPrice(0, 1);
          }
        }
      }
    };

    const jiaAction = () => {
      if (lock.value) return false;
      lock.value = true;
      if (priceIndex.value + 1 >= bidPriceList.value.length) {
        getPrice(bidPriceList.value[priceIndex.value].bidNextPrice);
      } else {
        priceIndex.value = priceIndex.value + 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const jianAction = () => {
      if (priceIndex.value > 0) {
        if (lock.value) return false;
        lock.value = true;
        priceIndex.value = priceIndex.value - 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };
    const commentEL = ref(null); // 聊天列表组件
    const loginNotiveEL = ref(null); // 登录用户展示组件
    const sockedMes = (d) => {
      // 接收socket通知
      if (d.type === "send_msg" || d.type === "send_msg_new") {
        // commentEL.value.sockedMes(d);
        return false;
      }
      if (d.type === "join_live_room") {
        // loginNotiveEL.value.sockedMes(d);
        return false;
      }
      if (d.type === "auction_top") {
        // 拍品置顶
        auctionData.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_bid") {
        // 拍品出价
        if (auctionData.value.product_id == d.data.id) {
          if (d.data.goTime) auctionData.value.goTime = d.data.goTime;

          if (albumData.value.album_type == 3) {
            auctionData.value.now_price = d.data.bid_record.bid_price;
            auctionData.value.is_confirm = d.data.bid_record.is_confirm;
          } else {
            auctionData.value.now_price = d.data.now_price;
          }

          auctionData.value.now_user = d.data.bid_record.uid;
          auctionData.value.now_user_name = d.data.bid_record.user.nickname;
          auctionData.value.now_user_avatar = d.data.bid_record.user.avatar;
        }
        getPrice(d.data.now_price, 2);
      } else if (d.type === "auction_bid_proxy") {
        // 代理出价
        if (auctionData.value.product_id == d.data.id) {
          if (d.data.goTime) auctionData.value.goTime = d.data.goTime;

          if (albumData.value.album_type == 3) {
            auctionData.value.now_price = d.data.bid_record_list[0].bid_price;
            auctionData.value.is_confirm = d.data.bid_record_list[0].is_confirm;
          } else {
            auctionData.value.now_price = d.data.now_price;
          }

          auctionData.value.now_user = d.data.bid_record_list[0].uid;
          auctionData.value.now_user_name =
            d.data.bid_record_list[0].user.nickname;
          auctionData.value.now_user_avatar =
            d.data.bid_record_list[0].user.avatar;
        }
        getPrice(d.data.now_price, 2);
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        if (auctionData.value.product_id == d.data.id) {
          auctionData.value.now_price = d.data.now_price;
          auctionData.value.now_user = d.data.bid_list[0].uid;
          auctionData.value.now_user_name = d.data.bid_list[0].user.nickname;
          auctionData.value.now_user_avatar = d.data.bid_list[0].user.avatar;

          getPrice(d.data.now_price, 2);
        }
      } else if (d.type === "auction_bid_confirm") {
        // 确认网络出价
        if (auctionData.value.product_id == d.data.id) {
          const record = d.data.bid_record;

          if (albumData.value.album_type == 3) {
            auctionData.value.now_price = record.bid_price;
            auctionData.value.is_confirm = record.is_confirm;
          } else {
            auctionData.value.now_price = d.data.now_price;
          }
          auctionData.value.now_user = record.uid;
          auctionData.value.now_user_name = record.user.nickname;
          auctionData.value.now_user_avatar = record.user.avatar;
          getPrice(auctionData.value.now_price, 2);
        }
      } else if (d.type === "auction_start") {
        // 确认起拍价
        auctionData.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_end" || d.type === "auction_revoke") {
        // 拍品结束
        if (d.data.next_auction) {
          auctionData.value = d.data.next_auction;
          initPriceRule(d.data.next_auction);
          initPrice(d.data.next_auction);
        } else {
          auctionData.value.auction_status = 5;
        }
      } else if (d.type === "auction_remake") {
        // 重拍
        auctionData.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_edit_bidRange") {
        // 切换竞价阶梯
        if (auctionData.value.product_id == d.data.auction_id) {
          auctionData.value.bid_range_id = d.data.bidRange_info.id;
          auctionData.value.bidRange = d.data.bidRange_info;
          initPriceRule(auctionData.value);
          initPrice(auctionData.value);
        }
      }
    };

    const payOk = () => {
      // 保证金支付成功
      showPayBondMoney.value = false;
      checkAlbumBondPayAction();
    };
    const payClear = () => {
      // 取消支付保证金
      showPayBondMoney.value = false;
    };

    const goRecharge = () => {
      route.push("/users/recharge");
    };

    let bidLock = false;
    const showBidPrice = ref(1000); // 展示要出的金额
    const showBidEL = ref(false); // 确认弹窗
    const kong = () => {};
    const clearBidAction = () => {
      // 取消出价
      showBidEL.value = false;
      showBidPrice.value = "";
    };
    const bidOkAction = () => {
      if (bidLock) return false;
      bidLock = true;
      const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
      const auction_id = auctionData.value.product_id;

      auctionBidPrice({
        bid_price: money_bid,
        auction_id: auction_id,
        is_anonymous: is_anonymous.value,
      })
        .then(() => {
          clearBidAction();
          bidLock = false;
        })
        .catch((message) => {
          clearBidAction();
          Toast(message);
          bidLock = false;
        });
    };
    const bidAction = () => {
      // 出价
      if (auctionData.value.auction_status === 0) {
        Toast("拍品还未开始");
        return false;
      } else if (auctionData.value.auction_status === 1) {
        const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
        if (!showBidEL.value) {
          showBidPrice.value = money_bid;
          showBidEL.value = true;
          return false;
        }
      }
    };
    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });

    const showListAction = () => {
      auctionList.value.changeStatue = 1;
      auctionList.value.finished = false;
      auctionList.value.loading = false;
      auctionList.value.list = [];
      auctionList.value.queryParams.album_id = albumData.value.album_id;
      auctionList.value.queryParams.page = 1;
      auctionList.value.total = 0;
      auctionList.value.show = true;
      auctionList.value.loadList();
      auctionList.value.loadAlbumData(albumData.value.album_id);
      // 查看拍品列表
    };
    const showDetail = (id) => {
      router.push("/pages/auctionDetail/index?id=" + id);
    };

    const onFinish = () => {
      if (auctionData.value.now_price) {
        auctionData.value.auction_status = 5;
      } else {
        auctionData.value.auction_status = 4;
      }
    };
    const showDetailAction = () => {
      if (auctionDetailEL.value) {
        auctionDetailEL.value.loadInit(auctionData.value.product_id);
      }
    };
    // 获取用户名
    const uname = computed(() => {
      return store.getters.uname;
    });
    // 获取用户头像
    const avatar = computed(() => {
      return store.getters.avatar;
    });

    const is_anonymous = ref(1);
    const changeAnonymous = () => {
      console.log(auctionData.value.userLastBidRecord, compel_anonymous.value);
      if (auctionData.value.userLastBidRecord) {
        is_anonymous.value = auctionData.value.userLastBidRecord.is_anonymous;
      } else if (compel_anonymous.value === 1) {
        is_anonymous.value = 1;
      }
    };

    return {
      roomId,
      albumId,
      albumData,
      auctionData,
      bidPriceList,
      priceIndex,
      isPayBond,
      userNumber,
      userNumberType,
      vanSwiper,
      vanSwiperBid,
      formatPriceNumber,
      canBidPrice,
      rightBtnStyle,
      showPayBondMoney,
      account,
      colorData,
      commentEL,
      loginNotiveEL,
      uid,
      uname,
      avatar,
      auctionList,
      auctionDetailEL,
      showBidPrice,
      showBidEL,
      is_anonymous,
      compel_anonymous,
      merchantInfo,
      showType,

      loadAlbumDetail,
      loadNowAuction,
      sockedMes,
      jiaAction,
      jianAction,
      useNumberPlateAction,
      payBondAction,
      payOk,
      payClear,
      loadMsgAction,
      goRecharge,
      bidAction,
      showListAction,
      showDetail,
      onFinish,
      showDetailAction,
      bidOkAction,
      clearBidAction,
      kong,
      changeAnonymous,
    };
  },
};
</script>

<style lang="scss" scoped>
.left-bottom {
  position: fixed;
  left: 30px;
  width: 440px;
  bottom: 162px;
  z-index: 1000;

  .btn {
    height: 64px;
    width: 440px;
    text-align: center;
    line-height: 64px;
  }

  .bid-view {
    border-radius: 28px;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);

    .bid-view-top {
      color: #fff;
      font-size: 20px;

      .flex-one {
        margin-left: 10px;
      }

      .r {
        width: 96px;
        height: 36px;
        border-radius: 21px;
        border: 2px solid #ffffff;
        text-align: center;
        line-height: 36px;
      }
    }

    .bid-view-bottom {
      margin-top: 16px;
      .jia,
      .jian {
        width: 48px;
        height: 48px;

        img {
          width: 48px;
          height: 48px;
          display: block;
        }
      }

      .num {
        height: 64px;
        margin: 0 16px;

        .bid-price-list {
          height: 60px;
          margin-left: 8px;

          .bid-p {
            height: 60px;
            line-height: 60px;
          }
        }
      }
    }
  }
}

.auction-view.nochange {
  top: 330px;
  z-index: 1000;
  overflow: hidden;
  width: 120px;
  padding-bottom: 10px;
  background: linear-gradient(
    90deg,
    rgba(255, 245, 227, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: 1px solid #fff5e2;
  position: absolute;
  right: 10px;

  .line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .auction-status-view {
    flex: 1;
    font-weight: 500;
    color: #9c3117;
  }
  .auction-status.nochange {
    height: 22px;
    padding: 0 6px;
    background: linear-gradient(270deg, #ffffff 0%, #ffeecc 100%);
    box-shadow: 0px 1px 0px 0px #f6e7cb;
    border-radius: 4px 4px 0px 0px;
    color: #8b6244;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.jjjp {
      color: #fff;
      background: linear-gradient(270deg, #ff6565 0%, #e52424 100%);
      box-shadow: 0px 2px 0px 0px #f6e7cb;
    }

    .mr4 {
      margin-right: 2px;
    }
  }

  .auction-content.nochange {
    padding: 3px 3px 0 3px;

    .name.nochange {
      text-align: left;
      color: #8b6244;
      line-height: 16px;
      height: 32px;
      font-size: 12px;
    }

    .price.nochange {
      margin-top: 3px;
      font-weight: 600;
      color: #d5303e;
      line-height: 21px;
      font-size: 15px;
    }

    .price.nochange span {
      font-family: NUM;
    }

    .num.nochange {
      margin-top: 2px;
      font-weight: 400;
      color: #c1955c;
      line-height: 16px;
      font-size: 11px;
    }

    .num1.nochange {
      color: #d5303e;
    }

    .buyer.nochange {
      margin-top: 4px;
      font-weight: 500;
      color: #8b6244;
      line-height: 18px;
      font-size: 12px;
    }

    .num.hong {
      color: #fb7575;
    }

    .num.huang {
      color: #c1955c;
    }

    .num.nochange .l {
      max-width: 60px;
      margin-right: 2px;
    }
  }
}

.btn-icon {
  position: fixed;
  left: 32px;
  bottom: 68px;
  width: 64px;
  height: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  border-radius: 45px;
  img {
    width: 40px;
    height: 40px;
    display: block;
  }
}

.confirm-bid {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.confirm-bid .confirm-bid-content {
  background: rgba(0, 0, 0, 0.7);
  width: 560px;
  margin-top: 400px;
  border-radius: 16px;
}
.confirm-bid .confirm-bid-content .confirm-bid-user {
  margin-top: 30px;
  margin-left: 32px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}
.confirm-bid .confirm-bid-content .confirm-bid-user img {
  width: 48px;
  height: 48px;
  display: block;
  margin-right: 12px;
  border-radius: 50%;
}
.confirm-bid .confirm-bid-content .confirm-bid-price {
  font-size: 56px;
  font-family: NUM;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 26px;
}
.confirm-bid-number {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  margin-top: 40px;
  padding: 0 84px;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns {
  border-top: 2px solid rgba(255, 255, 255, 0.05);
  display: flex;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn {
  height: 80px;
  line-height: 80px;
  flex: 1;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #999;
}
.confirm-bid .confirm-bid-content .confirm-bid-btns .confirm-bid-btn.q {
  color: red;
  border-left: 2px solid rgba(255, 255, 255, 0.05);
}

.all-bg.nochange {
  position: fixed;
  width: 375px;
  bottom: 0;
  left: 50%;
  margin-left: -197.5px;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top-bg.nochange {
  width: 100%;
  filter: blur(10px);
  transform: scale(1.1);
}

.bottom-bg {
  width: 100%;
}

.top-bg img {
  width: 100%;
  height: 100%;
  display: block;
}

.auction-info.nochange {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 790px;
  height: 116px;
  background: linear-gradient(
    90deg,
    rgba(255, 245, 227, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border: 1px solid #fff5e2;
  z-index: 1000;
  box-sizing: border-box;
  padding: 14px 30px 14px 24px;
  transition: bottom 0.5s;

  .auction-info-top .auction-info-status {
    width: 90px;
    height: 36px;
    background: #d5303e;
    border-radius: 18px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
  }

  .auction-info-top .auction-info-name {
    flex: 1;
    margin-left: 8px;
    font-size: 26px;
    font-weight: 600;
    color: #8b6244;
  }

  .auction-info-top .auction-num {
    margin-left: 48px;
    font-size: 20px;
    font-weight: 500;
    color: #8b6244;
  }

  .auction-info-bottom {
    margin-top: 10px;
    height: 36px;
  }

  .auction-info-user .user-avatar {
    width: 36px;
    height: 36px;
    display: block;
  }

  .auction-info-user .user-avatar image {
    width: 36px;
    height: 36px;
    display: block;
    border-radius: 50%;
  }

  .auction-info-user .user-name {
    margin-left: 8px;
    font-size: 24px;
    font-weight: 500;
    color: #8b6244;
    line-height: 36px;
    max-width: 110px;
  }

  .auction-info-price {
    margin-left: 8px;
    font-size: 30px;
    font-weight: 600;
    color: #d5303e;
  }

  .auction-info-bottom .is-confirm {
    font-size: 20px;
    font-weight: 500;
    color: #fb7575;
    margin-left: 8px;
  }

  .auction-info-bottom .is-wait {
    font-size: 20px;
    font-weight: 500;
    color: #c1955c;
    margin-left: 8px;
  }

  .auction-count {
    font-size: 22px;
    font-weight: 600;
    color: #c1955c;
  }
  .auction-count .jjjp {
    color: #d5303e;
  }
}
</style>

<style>
.auction-status .van-count-down {
  line-height: 40px;
  font-size: 20px;
  color: #e50000;
}
</style>